@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
    .container {
        max-width: var(--maxwidth-container);
        padding: 0 50px;
        margin: 0 auto;
        @media screen and (max-width: 64em) {
            padding: 0 15px;
        }
    }
    .overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 50;
        background-color: rgba(0, 0, 0, 0.8);
    }
}
@layer base {
    :root {
        --pri: #c4a946;
        --black: #28261d;
        --white: #fff;
        --body: #233030;
        --maxwidth-container: 1540px;
    }

    html {
        font-size: 62.5%;
    }
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    * {
        margin: 0;
        padding: 0;
        font: inherit;
    }
    img,
    picture,
    video,
    canvas,
    svg {
        display: block;
        max-width: 100%;
    }
    input,
    button,
    textarea,
    select {
        outline: none;
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        overflow-wrap: break-word;
    }

    body {
        font-family: 'Raleway', sans-serif;
        line-height: 1.5;
        font-weight: 400;
        text-rendering: optimizeSpeed;
        font-size: 1.6rem;
        background-color: var(--body);
        color: var(--white);
    }

    button,
    input,
    [tabindex] {
        border: none;
        outline: none;
    }
    a {
        text-decoration: none;
        color: var(--white);
    }
    button {
        cursor: pointer;
        background-color: transparent;
        color: var(--white);
        border: none;
    }
    li {
        list-style: none;
    }
    @font-face {
        font-family: Onramp;
        src: url('./assets/fonts/ONRAMP.ttf');
    }
}
